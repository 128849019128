import React, { useState } from 'react'
import { Link, graphql } from 'gatsby'
import styled from 'styled-components'
import GoogleMapReact from 'google-map-react'

import { Layout, Map } from '../components'
import { GOOGLE_MAPS_KEY } from '../constants'
import { mapTheme } from '../data/map'

import { container, padding, content, pageTitle, bgIcon } from '../styles/global';
import { media } from '../styles/utils';
import Post from '../components/Post/Post'

const VenuePost = ({ pageContext, ...props }) => {
	const data = pageContext.acf_json

	return (
		<Layout
			meta={null}
			menu={pageContext.menu}
		>
			<Container>
				<Heading>{pageContext.sectionTitle}</Heading>

				<Content>
					<Post
						title={data.title}
						date={data.date}
					// link={data.link}
					>
						{data.content}
					</Post>
				</Content>
			</Container>
		</Layout>
	)
}


// Shared

const Heading = styled.div``
const Subheading = styled.div``

// Layout

const Container = styled.div`
	${container}
	${padding}
	flex-direction: column;
	align-items: center;
	flex: 1;

	> ${Heading} {
		${pageTitle}
	}
	background-color: #F1BFB2;
`

const Content = styled.div`
	${content}
	justify-content: flex-start;
`

// Contact Info

const Text = styled.div`
    font-size: 22px;
    line-height: 26px;
	text-align: center;
	font-weight: bold;
	text-transform: uppercase;

    sup {
        font-size: 14px;
        line-height: 0;
        transform: translateY(3px);
        display: inline-block;
	}

	${media.phone`
		p, a {
			font-size: 16px;
			line-height: 20px;
		}

		sup {
			font-size: 12px;
			transform: translateY(4px);
		}
	`}
`

const Location = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-bottom: 128px;

  ${Heading} {
    ${pageTitle}
    margin: 48px 0 40px;
  }

  ${Text} {
    margin-top: 52px;
  }

  ${media.phone`
		padding-top: 48px;

		${Text} {
			margin-top: 40px;
		}
	`}
`

const MapWrapper = styled.div`
  height: 500px;
  width: 100%;
  max-width: 940px;
	margin-bottom: 22px;

  ${media.phone`
		margin-top: 32px;
		height: 220px;
	`}
`

const Marker = styled.div`
  background-image: url(${require('../assets/icons/map-marker.svg')});
  width: 19px;
  height: 33px;
  ${bgIcon}
`

const Socials = styled.div`
    text-align: center;
    font-size: 16px;
`



export default VenuePost

