import React, { useState } from 'react'
import { connect } from 'react-redux'
import { navigate, Link } from 'gatsby'
import styled, { css } from 'styled-components'
import { container, padding, content, pageTitle, bgIcon } from '../../styles/global';

const Post = ({ title, link, date, children, ...rest }) => {

	return (
		<PostWrapper>
			<Heading>
				{link ? (
					<TitleLink to={link}>
						{title}
					</TitleLink>
				) : (
					<Title>
						{title}
					</Title>
				)}
				<Date>
					{date}
				</Date>
			</Heading>


			<Text
				dangerouslySetInnerHTML={{ __html: children }}
			/>

			{link && (
				<ButtonWrapper>
					<LinkButton to={link}>
						View Post
					</LinkButton>
				</ButtonWrapper>
			)}
		</PostWrapper>
	)
}

const PostWrapper = styled.div`
	background-color: white;
  padding: 20px;
	margin-bottom: 20px;
	width: 100%;
	max-width: 80%;
`

const Heading = styled.div`
	display: flex;
	justify-content: space-between;
	margin-bottom: 20px;

`

const Text = styled.div`
	
	strong {
		font-weight: bold;
	}

	a {
		text-decoration: underline;
	}
`

const TitleLink = styled(Link)`
	font-size: 24px;
`

const LinkButton = styled(Link)`
	display: flex;
	align-items: center;

	color: black;
	background-color: #f8e1da;
	padding: 10px;
	border-radius: 5px;

	font-weight: bold;
	line-height: 18px;
	text-transform: uppercase;
`

const ButtonWrapper = styled.div`
	display: flex;
	justify-content: flex-end;
`

const Title = styled.div`
	font-size: 24px;
`

const Date = styled.div`
	font-style: italic;
`

export default Post
